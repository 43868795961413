import { Badge, Divider, Space } from '@arco-design/web-react';
import { IconMessage } from '@arco-design/web-react/icon';

const App = () => {
    return (
        <Space size='large' direction='vertical'>
            <Space size='large'>
                <Badge status='error' text='damesck的联系方式' />
                <Badge status='default' text='mail@damesck.net' />
                <Badge status='default' text='最后更新：2024 年 9 月 2 日' />
            </Space>
        </Space>
    );
};

export default App;
