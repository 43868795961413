import { Comment, Avatar, Button, Input } from '@arco-design/web-react';
import { IconMessage } from '@arco-design/web-react/icon';
import { Message } from '@arco-design/web-react';


const App = () => {
    return (
        <Comment
            align='right'
            actions={
                <span className='custom-comment-action'>
          <IconMessage /> Reply
        </span>
            }
            author='雪球'
            avatar='https://avatars.githubusercontent.com/u/97330394?s=96&v=4'
            content={
                <div>
                    大妈我爱你
                </div>
            }
            datetime='2024 年 9 月 2 日'
        >
            <Comment
                align='right'
                actions={[
                    <Button
                        onClick={() => Message.error('提示信息：嘿嘿你没有权限评论')}
                        type='primary'
                    >
                        评论
                    </Button>,
                ]}
                avatar='//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/e278888093bef8910e829486fb45dd69.png~tplv-uwbnlip3yd-webp.webp'
                content={
                    <div>
                        <Input.TextArea placeholder='回复' />
                    </div>
                }
            ></Comment>
        </Comment>

    );
};


export default App;
